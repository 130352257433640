import React, { useEffect, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { createStyles, Theme } from '@material-ui/core/styles';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  InputBase,
  CircularProgress,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { getAccessToken } from '../../utils/index';
import { Toaster } from '../../components/Toaster';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { store } from '../../stores';
import SyncIcon from '@material-ui/icons/Sync';
import { Auth } from 'aws-amplify';
import { VaccineBackdrop } from '../../components/Backdrop';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    header: {
      fontWeight: 600,
      whiteSpace: 'nowrap',
    },
    uploadBtn: {
      backgroundColor: 'white',
      marginLeft: '2rem',
      marginRight: '1rem',
    },
    textField: {
      borderBottom: '1px solid white',
      color: 'white',
      fontSize: '0.9rem',
    },
  }),
);

export default function DashboardPage() {
  const user = store.getState().userReducer.user?.email;
  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
  const classes = useStyles();
  // const history = useHistory();
  const fetchData = (isMore: boolean) => {
    setDataLoading(true);
    if (!isMore) {
      setLastEvaluatedKey(null);
      setData([]);
    }
    axios
      .get('https://hgbygy47ub.execute-api.us-east-1.amazonaws.com/prod/upload/history', {
        headers: {
          Authorization: getAccessToken(),
          email: user,
          lastEvaluatedKey: !isMore ? JSON.stringify(null) : JSON.stringify(lastEvaluatedKey),
        },
      })
      .then((res) => {
        if (data.length === 0 || !isMore) {
          setData(res.data.Items);
        } else {
          let temp = [...data];
          temp = temp.concat(res.data.Items);
          setData(temp);
        }
        if (res.data.LastEvaluatedKey) setLastEvaluatedKey(res.data.LastEvaluatedKey);
        else setLastEvaluatedKey(null);
        setDataLoading(false);
      })
      .catch((e) => {
        // console.log(e);
        Toaster('Error Getting File History. Try Again.', 'danger');
        setDataLoading(false);
      });
  };

  useEffect(() => {
    fetchData(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const downloadFile = async (name: string, timestamp: string) => {
    const accessToken = (await Auth.currentSession()).getAccessToken().getJwtToken();
    const params = { fileName: name, timestamp: timestamp, email: user };
    setDownloading(true);
    axios
      .get('https://hgbygy47ub.execute-api.us-east-1.amazonaws.com/prod/upload/file', {
        params: params,
        headers: {
          'Content-Type': 'application/json',
          Authorization: accessToken,
        },
      })
      .then((res) => {
        // console.log(res);
        if (res.data === 'File Not Found' || res.data === 'Something Went Wrong') {
          Toaster(res.data, 'danger');
          setDownloading(false);
          return;
        }
        const win = window.open(res.data);
        if (win != null) {
          win.focus();
        }
        setDownloading(false);
      })
      .catch((e) => {
        // console.log(e);
        Toaster('Error Getting File. Try Again.', 'danger');
        setDownloading(false);
      });
  };

  const getDataView = () => {
    return data.length === 0 ? (
      <TableRow>
        <TableCell className="p-3 border-y border-gray-100 text-xs">No data</TableCell>
        <TableCell className="p-3 border-y border-gray-100 text-xs"> No data </TableCell>
        <TableCell className="p-3 border-y border-gray-100 text-xs"> No data </TableCell>
        <TableCell className="p-3 border-y border-gray-100 text-xs"> No data </TableCell>
      </TableRow>
    ) : (
      <>
        {data.map((item) => {
          return (
            <TableRow key={item.timestamp}>
              <TableCell className="p-3 border-y border-gray-100 text-xs">{item?.file_name}</TableCell>
              <TableCell className="p-3 border-y border-gray-100 text-xs">
                {new Date(item?.timestamp).toLocaleString()}{' '}
              </TableCell>
              <TableCell className="p-3 border-y border-gray-100 text-xs">{item?.status}</TableCell>
              <TableCell className="p-3 border-y border-gray-100 text-xs">
                <Button
                  variant="contained"
                  component="label"
                  color="primary"
                  onClick={() => downloadFile(item?.file_name, item?.timestamp)}
                  disabled={item?.status === 'Upload In Progress (Processing data)'}
                >
                  Get
                </Button>
              </TableCell>
            </TableRow>
          );
        })}
      </>
    );
  };
  const onChangeFileHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = e.target.files;
    if (fileList !== null && fileList.length > 0) {
      const accessToken = (await Auth.currentSession()).getAccessToken().getJwtToken();
      setFile(fileList[0]);
      setLoading(true);
      axios
        .post(
          'https://hgbygy47ub.execute-api.us-east-1.amazonaws.com/prod/data',
          { fileName: fileList[0]?.name, email: user },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: accessToken,
            },
          },
        )
        .then((res) => {
          // const resObj = JSON.parse(res.data.body);
          // console.log(res.data.presigned_url);

          axios
            .put(res.data.presigned_url, fileList[0], {
              headers: {
                'Content-Type': fileList[0].type,
              },
            })
            .then((res) => {
              Toaster('Uploaded', 'success');
              setLoading(false);
            })
            .catch((e) => {
              Toaster('Error Uploading. Try Again.', 'danger');
              setLoading(false);
            });
        })
        .catch((e) => {
          Toaster('Error Uploading. Try Again.', 'danger');
          setLoading(false);
        });
    }
  };

  return (
    <div>
      <VaccineBackdrop open={downloading}></VaccineBackdrop>
      <div className="min-h-screen max-w-7xl p-6  mx-auto">
        <AppBar position="static" className="py-2" color="primary">
          <Toolbar className="flex justify-between md:flex-row flex-col">
            <Typography variant="h6" noWrap>
              Dashboard
            </Typography>
            <div className="flex md:flex-row flex-col">
              <span>
                <InputBase className={classes.textField} value={file === null ? 'No File Selected' : file?.name} />
              </span>
              <Button variant="contained" component="label" className={classes.uploadBtn}>
                {!loading ? (
                  <>
                    Upload File
                    <input
                      type="file"
                      hidden
                      onChange={(e) => onChangeFileHandler(e)}
                      accept=".csv"
                      // , application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
                    />
                  </>
                ) : (
                  <>
                    <CircularProgress size={25} color="secondary" />
                  </>
                )}
              </Button>
              <IconButton onClick={() => fetchData(false)} size="small">
                <SyncIcon htmlColor="white" />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        <div className="shadow-md bg-white">
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.header} align="left">
                    File Name
                  </TableCell>
                  <TableCell className={classes.header} align="left">
                    Timestamp
                  </TableCell>
                  <TableCell className={classes.header} align="left">
                    Status
                  </TableCell>
                  <TableCell className={classes.header} align="left">
                    Download
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataLoading ? (
                  <TableRow>
                    <TableCell className="p-3 border-y border-gray-100 text-xs">Loading</TableCell>
                    <TableCell className="p-3 border-y border-gray-100 text-xs">Loading</TableCell>
                    <TableCell className="p-3 border-y border-gray-100 text-xs">Loading</TableCell>
                    <TableCell className="p-3 border-y border-gray-100 text-xs">Loading</TableCell>
                  </TableRow>
                ) : (
                  getDataView()
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        {lastEvaluatedKey && (
          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
            <Button variant="contained" color="primary" onClick={() => fetchData(true)}>
              Show More
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
