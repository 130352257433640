import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Redirect, Route, RouteProps, Switch, useHistory } from 'react-router-dom';
import { Loader } from './components/Loader';
import Navbar from './components/navbar';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import LoginPage from './pages/LoginPage';
import SignupPage from './pages/SignupPage';
import { NoMatch } from './pages/NoMatch';
import { checkAdminsGroup, checkUsersGroup, isAuthenticate, setAccessToken } from './utils';
import { useDispatch } from 'react-redux';
import { Auth } from 'aws-amplify';
import { loginAction, logoutAction } from './stores/user/Actions';
import DashboardPage from './pages/Dashboard/DashboardPage';
import ForgotPasswordVerificationPage from './pages/ForgotPasswordVerificationPage';
import { store } from './stores/index';
import Users from './pages/Users/Users';
import Doc from './pages/Doc';

export const PrivateRoute = ({ children, ...rest }: RouteProps) => (
  <Route
    {...rest}
    render={({ location }) =>
      isAuthenticate() ? (
        children
      ) : (
        <Redirect
          to={{
            pathname: '/',
            state: { from: { pathname: location.pathname } },
          }}
        />
      )
    }
  />
);
export const AdminRoute = ({ children, ...rest }: RouteProps) => {
  const roles = store.getState().userReducer.user?.role || [];
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticate() && checkAdminsGroup(roles) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: { pathname: location.pathname } },
            }}
          />
        )
      }
    />
  );
};

const App = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    async function getUser() {
      try {
        const res = await Auth.currentAuthenticatedUser({
          bypassCache: true, // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        });
        const role = res.signInUserSession.idToken.payload['cognito:groups'] || [];
        var isUser = checkUsersGroup(role);

        if (!isUser) {
          await Auth.signOut();
          dispatch(logoutAction());
          history.push('/');
          return;
        }

        const jwt_token = res.signInUserSession.accessToken.jwtToken;
        setAccessToken(jwt_token);

        dispatch(
          loginAction({
            email: res.attributes.email,
            username: res.username,
            sub: res.attributes.sub,
            role: role,
          }),
        );
      } catch (error) {
        dispatch(logoutAction());
      }
    }
    getUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <BrowserRouter>
      <Suspense fallback={<Loader />}>
        <Navbar />
        <Switch>
          <Route exact path="/" render={() => (isAuthenticate() ? <Redirect to="/dashboard" /> : <LoginPage />)} />
          <Route exact path="/login" render={() => (isAuthenticate() ? <Redirect to="/dashboard" /> : <LoginPage />)} />
          <Route
            exact
            path="/sign-up"
            render={() => (isAuthenticate() ? <Redirect to="/dashboard" /> : <SignupPage />)}
          />
          <Route
            exact
            path="/forgot-password"
            render={() => (isAuthenticate() ? <Redirect to="/dashboard" /> : <ForgotPasswordPage />)}
          />
          <Route
            exact
            path="/forgot-password/verification"
            render={() => (isAuthenticate() ? <Redirect to="/dashboard" /> : <ForgotPasswordVerificationPage />)}
          />

          {/* <Route exact path="/doc" render={()=> isAuthenticate()?<Doc/>:<Redirect to="/" />} /> */}
          <Route exact path="/doc" render={() => <Doc />} />
          {/* <PrivateRoute exact path="/doc">
            <Doc />
          </PrivateRoute> */}

          <PrivateRoute exact path="/dashboard">
            <DashboardPage />
          </PrivateRoute>

          <AdminRoute exact path="/users">
            <Users />
          </AdminRoute>
          <Route path="*">
            <NoMatch />
          </Route>
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

export default App;
