import React from 'react'
// @ts-ignore: Unreachable code error
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"
import doc from "../resources/doc/doc.json"

export default function Doc() {
    return (
        <div>
            <SwaggerUI spec={doc} />
        </div>
    )
}
