import { createMuiTheme } from '@material-ui/core/styles';
export const Theme = createMuiTheme({
    palette: {
        secondary: {
            main: '#C2002F'
        },
        primary: {
            main: '#212844'
        }
    },
    typography: {
        "fontFamily": `ussf, sans-serif`,
    }
});