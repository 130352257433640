import React, { useEffect, useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { createStyles, Theme } from '@material-ui/core/styles';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
// import { getAccessToken } from '../../utils/index';
import { Toaster } from '../../components/Toaster';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import SyncIcon from '@material-ui/icons/Sync';
import { VaccineBackdrop } from '../../components/Backdrop';
import { Auth } from 'aws-amplify';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    header: {
      fontWeight: 600,
      whiteSpace: 'nowrap',
    },
    uploadBtn: {
      backgroundColor: "white",
      marginLeft: "2rem"
    },
    textField: {
      borderBottom: "1px solid white",
      color: "white",
      fontSize: "0.9rem"
    },
  }),
);



export default function WaitingUsers() {
  const [loading, setLoading] = useState(false);
  const [taskInProgress, setTaskInProgress] = useState(false);
  const [data, setData] = useState<any[]>([]);
  const classes = useStyles();


  const fetchData = async () => {
    const accessToken = (await Auth.currentSession()).getAccessToken().getJwtToken()
    setLoading(true);
    axios.get("https://hgbygy47ub.execute-api.us-east-1.amazonaws.com/prod/users/unassigned", {
      headers: {
        'Authorization': accessToken,
      }
    }).then(res => {
      // console.log(res);

      setData(res.data);
      setLoading(false);
    }).catch((e) => {
      Toaster("Something went wrong", 'danger');
      setLoading(false);
    })
  }

  const acceptUserHandler = async (userName: string, email: string) => {
    // console.log(userName);
    setTaskInProgress(true);
    const accessToken = (await Auth.currentSession()).getAccessToken().getJwtToken()
    axios.post("https://hgbygy47ub.execute-api.us-east-1.amazonaws.com/prod/users/unassigned", { userName: userName, type: "accept", email: email },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': accessToken
        },
      }).then((res) => {

        // const resObj = JSON.parse(res.data.body);
        // console.log(res);
        Toaster(res.data, 'success');
        setTaskInProgress(false);
        fetchData();

      }).catch((e) => {
        Toaster("Something wen wrong. Try Again.", 'danger');
        setTaskInProgress(false);
      })
  }

  const rejectUserHandler = async (userName: string, email: string) => {
    setTaskInProgress(true);
    const accessToken = (await Auth.currentSession()).getAccessToken().getJwtToken()
    axios.post("https://hgbygy47ub.execute-api.us-east-1.amazonaws.com/prod/users/unassigned", { userName: userName, type: "reject", email: email },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': accessToken
        },
      }).then((res) => {

        // const resObj = JSON.parse(res.data.body);
        // console.log(res);
        Toaster(res.data, 'success');
        setTaskInProgress(false);
        fetchData();

      }).catch((e) => {
        Toaster("Something went wrong. Try Again.", 'danger');
        setTaskInProgress(false);
      })
  }

  useEffect(() => {

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getDataView = () => {
    return data.length === 0 ? <TableRow>
      <TableCell className="p-3 border-y border-gray-100 text-xs">No data</TableCell>
      <TableCell className="p-3 border-y border-gray-100 text-xs">No data</TableCell>
      <TableCell className="p-3 border-y border-gray-100 text-xs">No data</TableCell>
      <TableCell className="p-3 border-y border-gray-100 text-xs">No data</TableCell>
    </TableRow> : <>
      {data.map(item => {
        return (
          <TableRow key={item.Email}>
            <TableCell className="p-3 border-y border-gray-100 text-xs">{item?.Email}</TableCell>
            <TableCell className="p-3 border-y border-gray-100 text-xs">{item?.Username} </TableCell>
            <TableCell className="p-3 border-y border-gray-100 text-xs"><Button variant="contained" color="primary" onClick={() => acceptUserHandler(item?.Username, item.Email)}>Accept</Button></TableCell>
            <TableCell className="p-3 border-y border-gray-100 text-xs"><Button variant="contained" color="secondary" onClick={() => rejectUserHandler(item?.Username, item.Email)}>Reject</Button></TableCell>
          </TableRow>)
      }
      )}
    </>
  }

  return (
    <>
      <VaccineBackdrop open={taskInProgress} ></VaccineBackdrop>
      <div className="max-w-7xl p-6  mx-auto">
        <AppBar position="static" className="py-2" color="primary">
          <Toolbar className="flex justify-between md:flex-row flex-col">
            <span>
              <Typography variant="h6" noWrap>
                Users
              </Typography>
              <Typography variant="body2" noWrap>
                waiting for confirmation
              </Typography>
            </span>
            <IconButton onClick={fetchData} size="small">
              <SyncIcon htmlColor="white" />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div className="shadow-md bg-white">
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className={classes.header} align="left">
                    Email
                  </TableCell>
                  <TableCell className={classes.header} align="left">
                    UserId
                  </TableCell>
                  <TableCell className={classes.header} align="left">
                    Accept
                  </TableCell>
                  <TableCell className={classes.header} align="left">
                    Reject
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>

                {loading ?
                  <TableRow>
                    <TableCell className="p-3 border-y border-gray-100 text-xs">Loading</TableCell>
                    <TableCell className="p-3 border-y border-gray-100 text-xs">Loading</TableCell>
                    <TableCell className="p-3 border-y border-gray-100 text-xs">Loading</TableCell>
                    <TableCell className="p-3 border-y border-gray-100 text-xs">Loading</TableCell>
                  </TableRow> : getDataView()
                }

              </TableBody>

            </Table>
          </TableContainer>
        </div>
      </div>
      <div>
      </div>
    </>
  );
}
