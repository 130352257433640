import React from 'react';
import AssignVendors from './AssignVendor';

import WaitingUsers from './WaitingUsers';




export default function Users() {

  return (
    <div>
      <WaitingUsers/>
      <AssignVendors/>
    </div>
  );
}
