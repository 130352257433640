import React from 'react';
import styles from './Loader.module.css';

export const Loader = () => {
  return (
    <div className="w-full h-screen flex justify-center -mt-24 items-center bg-white">
      <div className={styles.sk_chase}>
        <div className={styles.sk_chase_dot}></div>
        <div className={styles.sk_chase_dot}></div>
        <div className={styles.sk_chase_dot}></div>
        <div className={styles.sk_chase_dot}></div>
        <div className={styles.sk_chase_dot}></div>
        <div className={styles.sk_chase_dot}></div>
      </div>
    </div>
  );
};
