import { yupResolver } from '@hookform/resolvers/yup';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import { Auth } from 'aws-amplify';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import VIcon from '../components/icons';
import { Toaster } from '../components/Toaster';
import { emailRegex, lowercaseRegex, uppercaseRegex, numericRegex, specialCharRegex } from '../utils';
import * as Yup from 'yup';
import { VaccineBackdrop } from '../components/Backdrop';

interface IData {
  email: string;
  password: string;
}

const validationSchema = Yup.object().shape({
  email: Yup.string().required('Required').matches(emailRegex, 'Invalid email format'),
  password: Yup.string()
    .required('Required')
    .matches(lowercaseRegex, 'one lowercase required!')
    .matches(uppercaseRegex, 'one uppercase required!')
    .matches(numericRegex, 'one number required!')
    .matches(specialCharRegex, 'one special character required!')
    .min(8, 'Minimum 8 characters required!'),
});

const SignupPage = () => {
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmPassword,setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const handleConfirmPassField = (e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setConfirmPassword(e.target.value);
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const { register, handleSubmit, errors, formState: { isSubmitting } } = useForm<IData>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = handleSubmit(async (data) => {
    if(confirmPassword!== data.password){
      setConfirmPasswordError("Password does not match");
      return;
    }
    setConfirmPasswordError("");
    try {
      await Auth.signUp({
        username: data.email,
        password: data.password,

        attributes: {
          email: data.email,
        },
      });
      Toaster('Registration Successful', 'success');
      history.push('/login');
    } catch (e) {
      Toaster(e.message, 'danger');
    }
  });

  return (
    <div className=" flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <VaccineBackdrop open={isSubmitting} ></VaccineBackdrop>
      <div className="max-w-md w-full">
        <div className="bg-white shadow-sm border-2 border-blue-900 rounded-xl px-6 py-20 max-w-sm">
          <div>
            <h2 className="mb-6 text-center text-3xl font-extrabold text-gray-900">Sign Up</h2>
          </div>

          <form className="mt-8 space-y-6" onSubmit={onSubmit}>
            <div className="rounded-md shadow-sm space-y-8">
              <div>
                <TextField
                  type="text"
                  name="email"
                  fullWidth
                  variant="outlined"
                  placeholder="Email"
                  inputRef={register}
                  error={!!errors.email}
                />
                {errors.email && <p className="text-red-500 text-xs italic font-medium mt-1">{errors.email.message}</p>}
              </div>
              <div>
                <TextField
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  fullWidth
                  variant="outlined"
                  placeholder="Password"
                  inputRef={register}
                  error={!!errors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? (
                            <VIcon className="fill-current h-5 text-gray-600" name="eye-visible" />
                          ) : (
                            <VIcon className="fill-current h-5 text-gray-600" name="eye-hidden" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {errors.password && (
                  <p className="text-red-500 text-xs italic font-medium mt-1">{errors.password.message}</p>
                )}
              </div>
              <div>
                <TextField
                  type={showConfirmPassword ? 'text' : 'password'}
                  name="confirmpassword"
                  fullWidth
                  variant="outlined"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => handleConfirmPassField(e)}
                  error={confirmPasswordError!==""}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showConfirmPassword ? (
                            <VIcon className="fill-current h-5 text-gray-600" name="eye-visible" />
                          ) : (
                            <VIcon className="fill-current h-5 text-gray-600" name="eye-hidden" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {confirmPasswordError!=="" && (
                  <p className="text-red-500 text-xs italic font-medium mt-1">{confirmPasswordError}</p>
                )}
              </div>
            </div>

            <div className="flex items-center justify-center">
              <div className="text-sm">
                <Link to="/" className="font-medium text-blue-800 hover:text-blue-900">
                  Already have an account?
                </Link>
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="w-full py-4 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-800 hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Sign Up
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default SignupPage;
