import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { USER_TYPES } from './Types';

export type userRoleType = 'Admins' | 'dev-users' | 'Users';

export interface IUser {
  email: string;
  username: string;
  sub: string;
  role: userRoleType[];
}

interface IUserReducer {
  user: IUser | null;
}

const InitialState: IUserReducer = {
  user: null,
};

const login = (state: IUserReducer, action: any): IUserReducer => {
  return {
    ...state,
    user: action.payload,
  };
};

const logout = (state: IUserReducer, data: any): IUserReducer => {
  return {
    ...state,
    user: null,
  };
};

const Reducer = (state = InitialState, action: any) => {
  switch (action.type) {
    case USER_TYPES.LOGIN:
      return login(state, action);
    case USER_TYPES.LOGOUT:
      return logout(state, action);
    default:
      return state;
  }
};

// persistor for userReducer
const persistConfig = {
  key: 'userReducer',
  storage: storage,
  whitelist: ['user'],
};

export const userReducer = persistReducer(persistConfig, Reducer);
