import { IUser } from './Reducer';
import { USER_TYPES } from './Types';
import { removeAccessToken } from '../../utils/index';

export const loginAction = (payload: IUser) => {
  return { type: USER_TYPES.LOGIN, payload };
};

export const logoutAction = () => {
  removeAccessToken();
  return { type: USER_TYPES.LOGOUT };
};
