import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { emailRegex, lowercaseRegex, numericRegex, specialCharRegex, uppercaseRegex } from '../utils';
import * as Yup from 'yup';
import { TextField, InputAdornment, IconButton } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { VaccineBackdrop } from '../components/Backdrop';
import VIcon from '../components/icons';
import { Toaster } from '../components/Toaster';
import { Auth } from 'aws-amplify';

interface IData {
  code: string;
  email: string;
  newPassword: string;
}

const validationSchema = Yup.object().shape({
  code: Yup.string().required('Required'),
  email: Yup.string().required('Required').matches(emailRegex, 'Invalid email format'),
  newPassword: Yup.string()
    .required('Required')
    .matches(lowercaseRegex, 'one lowercase required!')
    .matches(uppercaseRegex, 'one uppercase required!')
    .matches(numericRegex, 'one number required!')
    .matches(specialCharRegex, 'one special character required!')
    .min(8, 'Minimum 8 characters required!'),
});

const ForgotPasswordVerificationPage = () => {
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmPassword,setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const handleConfirmPassField = (e:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setConfirmPassword(e.target.value);
  }

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };
  const [cognitoError, setCognitoError] = useState<string>();
  const {
    register,
    handleSubmit,
    errors,
    formState: { isSubmitting },
  } = useForm<IData>({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = handleSubmit(async (data) => {
    if(confirmPassword!== data.newPassword){
      setConfirmPasswordError("Password does not match");
      return;
    }
    setConfirmPasswordError("");
    try {
      await Auth.forgotPasswordSubmit(data.email, data.code, data.newPassword);
      Toaster('Password reset successful.', 'success');
      history.push('/');
    } catch (error) {
      setCognitoError(error.message ? error.message : error);
    }
  });

  return (
    <div className=" flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <VaccineBackdrop open={isSubmitting}></VaccineBackdrop>
      <div className="max-w-sm w-full">
        <div className="bg-white shadow-sm border-2 border-blue-900 rounded-xl px-6 py-20">
          <div>
            <h2 className="mb-6 text-center text-3xl font-extrabold text-gray-900">Reset Password</h2>
            <p className="text-center text-xs text-gray-400">
              A code has been sent to your email. Use the code to reset your password.
            </p>
          </div>

          <form className="mt-8 space-y-6" onSubmit={onSubmit}>
            <div className="rounded-md shadow-sm space-y-8">
              <div>
                <TextField
                  type="text"
                  name="code"
                  fullWidth
                  variant="outlined"
                  placeholder="Code"
                  inputRef={register}
                  error={!!errors.code}
                />
                {errors.code && <p className="text-red-500 text-xs italic mt-1">{errors.code.message}</p>}
              </div>
              <div>
                <TextField
                  type="text"
                  name="email"
                  fullWidth
                  variant="outlined"
                  placeholder="Email"
                  inputRef={register}
                  error={!!errors.email}
                />
                {errors.email && <p className="text-red-500 text-xs italic mt-1">{errors.email.message}</p>}
              </div>
              <div>
                <TextField
                  type={showPassword ? 'text' : 'password'}
                  name="newPassword"
                  fullWidth
                  variant="outlined"
                  placeholder="Password"
                  inputRef={register}
                  error={!!errors.newPassword}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? (
                            <VIcon className="fill-current h-5 text-gray-600" name="eye-visible" />
                          ) : (
                            <VIcon className="fill-current h-5 text-gray-600" name="eye-hidden" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {errors.newPassword && <p className="text-red-500 text-xs italic mt-1">{errors.newPassword.message}</p>}
              </div>
              <div>
                <TextField
                  type={showConfirmPassword ? 'text' : 'password'}
                  name="confirmpassword"
                  fullWidth
                  variant="outlined"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => handleConfirmPassField(e)}
                  error={confirmPasswordError!==""}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showConfirmPassword ? (
                            <VIcon className="fill-current h-5 text-gray-600" name="eye-visible" />
                          ) : (
                            <VIcon className="fill-current h-5 text-gray-600" name="eye-hidden" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {confirmPasswordError!=="" && (
                  <p className="text-red-500 text-xs italic font-medium mt-1">{confirmPasswordError}</p>
                )}
              </div>
            </div>
            {cognitoError && <p className="text-red-500 text-xs italic font-medium mt-1">{cognitoError}</p>}

            <div>
              <button
                type="submit"
                className="w-full py-4 px-4 border border-transparent text-sm rounded-md text-white bg-blue-800 hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordVerificationPage;
