import React from 'react';
import User from './User';
import Add from './Add';
import Cross from './Cross';
import ArrowUp from './ArrowUp';
import ArrowDown from './ArrowDown';
import ArrowRight from './ArrowRight';
import ArrowLeft from './ArrowLeft';
import Mic from './Mic';
import VisibilityOn from './VisibilityOn';
import VisibilityOff from './VisibilityOff';
import Check from './Check';
import Menu from './Menu';
import I from './I';
import Search from './Search';
export type IconNames =
  | 'user'
  | 'circle-plus'
  | 'cross'
  | 'menu'
  | 'arrow-up'
  | 'arrow-down'
  | 'arrow-left'
  | 'arrow-right'
  | 'eye-visible'
  | 'eye-hidden'
  | 'circle-check'
  | 'mic'
  | 'search'
  | 'i';

export interface IconProps {
  name: IconNames;
  className: string;
  style?: Object;
  fill?: string;
  width?: string | number;
  height?: string | number;
}

const VIcon = (props: IconProps) => {
  switch (props.name) {
    case 'menu':
      return <Menu {...props} />;
    case 'user':
      return <User {...props} />;
    case 'circle-plus':
      return <Add {...props} />;
    case 'cross':
      return <Cross {...props} />;
    case 'arrow-up':
      return <ArrowUp {...props} />;
    case 'arrow-down':
      return <ArrowDown {...props} />;
    case 'arrow-left':
      return <ArrowLeft {...props} />;
    case 'arrow-right':
      return <ArrowRight {...props} />;
    case 'mic':
      return <Mic {...props} />;
    case 'eye-visible':
      return <VisibilityOn {...props} />;
    case 'eye-hidden':
      return <VisibilityOff {...props} />;
    case 'i':
      return <I {...props} />;
    case 'circle-check':
      return <Check {...props} />;
    case 'search':
      return <Search {...props} />;

    default:
      return <span>no icon found with name: {props.name}</span>;
  }
};

export default VIcon;
