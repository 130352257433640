import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { TextField } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { emailRegex } from '../utils';
import { Auth } from 'aws-amplify';

interface IData {
  email: string;
}

const validationSchema = Yup.object().shape({
  email: Yup.string().required('Required').matches(emailRegex, 'Invalid email format'),
});

const ForgotPasswordPage = () => {
  const [cognitoError, setCognitoError] = useState<string>();
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm<IData>({
    resolver: yupResolver(validationSchema),
    mode: 'onBlur',
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      await Auth.forgotPassword(data.email);
      history.push('/forgot-password/verification');
    } catch (error) {
      setCognitoError(error.message ? error.message : error);
    }
  });

  return (
    <div className="h-screen flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-sm w-full">
        <div className="bg-white shadow-sm border-2 border-blue-900 rounded-xl px-6 py-20">
          <div>
            <h2 className="mb-6 text-center text-3xl font-extrabold text-gray-900">Reset Password</h2>
          </div>

          <form className="mt-8 space-y-6" onSubmit={onSubmit}>
            <div className="rounded-md shadow-sm space-y-8">
              <div>
                <TextField
                  type="text"
                  name="email"
                  fullWidth
                  variant="outlined"
                  placeholder="Email"
                  inputRef={register}
                  error={!!errors.email}
                />
                {errors.email && <p className="text-red-500 text-xs italic font-medium mt-1">{errors.email.message}</p>}
              </div>
            </div>

            {cognitoError && <p className="text-red-500 text-xs italic font-medium mt-1">{cognitoError}</p>}

            <div>
              <button
                type="submit"
                className="w-full py-4 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-800 hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Submit
              </button>
            </div>

            <div className="flex items-center justify-center">
              <div className="text-sm">
                <p>
                  Remember Password?
                  <Link to="/" className="font-medium text-blue-800 hover:text-blue-900">
                    {' '}
                    Log in
                  </Link>
                </p>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordPage;
